import React from "react"
import Layout from "../components/layout"
import { Container } from "reactstrap"
// import phone from "../assets/images/icon/phone.png"
// import email from "../assets/images/icon/email.png"
import Head from "../components/head"

// const hidden = {
// display: "none",
// listStyleType: "disc",
// paddingBottom: ".5rem",
// marginLeft: "2rem",
// fontSize: "14px",
// color: "#777575",
// }
const Contact = () => {
  return (
    <Layout>
      <Head
        title="Contact Us"
        description="Contact information for ThrowThings.com."
      />

      <Container>
        <section className=" contact-page section-b-space">
          <div className="container">
            <div className="row section-b-space">
              <div className="col-lg-7 map">
                <iframe title="Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3003.9473337170493!2d-81.24526218986085!3d41.157500535004104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8831397b305efd9b%3A0x564a6b89b9dffbfc!2sThrowthings.Com!5e0!3m2!1sen!2sus!4v1581601939488!5m2!1sen!2sus"
                  allowFullScreen></iframe>

                {/* src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d50059.12775918716!2d72.78534673554945!3d21.16564923510817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1533793756956"
                                    allowFullScreen></iframe> */}
              </div>
              <div className="col-lg-5">
                <h3>Contact Us</h3>

                <div className="contact-right">
                  <ul>
                    <li>
                      <div className="contact-icon">
                        <i className="fa fa-laptop" aria-hidden="true"></i>
                        <h6>Online</h6>
                      </div>
                      <div className="media-body">
                        <p>Fill out the form below</p>
                        {/* <p>info@shopcart.com</p> */}
                      </div>
                    </li>
                    <li>
                      <div className="contact-icon">
                        {/* <img src={email} alt="Email Icon" /> */}
                        <i className="fa fa-envelope-o" aria-hidden="true"></i>
                        <h6>Email</h6>
                      </div>
                      <div className="media-body">
                        <p>info@throwthings.com</p>
                        {/* <p>info@shopcart.com</p> */}
                      </div>
                    </li>

                    <li>
                      <div className="contact-icon">
                        {/* <img src={phone} alt="Phone Icon" /> */}
                        <i className="fa fa-phone" aria-hidden="true"></i>
                        <h6>Phone</h6>
                      </div>
                      <div className="media-body">
                        <p>(330) 297-1752</p>
                      </div>
                    </li>
                    <li>
                      <div className="contact-icon">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                        <h6>Address</h6>
                      </div>
                      <div className="media-body">
                        <p>150 N Chestnut St #241</p>
                        <p>Ravenna, OH 44266</p>
                        <p>Deliveries and Mail Only</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <form name="contact" method="post" className="theme-form" data-netlify="true" data-netlify-honeypot="bot-field">
                  {/* data-netlify-recaptcha="true" was making form not work */}
                  <input type="hidden" name="form-name" value="contact" />
                  <input type="hidden" name="bot-field" />
                  <div className="form-row">
                    <div className="col-md-6">
                      <label htmlFor="name">First Name</label>
                      <input type="text" className="form-control" id="name" placeholder="Enter Your First Name" name="first-name" />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="lastname">Last Name</label>
                      <input type="text" className="form-control" id="lastname" placeholder="Enter Your Last Name" name="last-name" />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="phone">Phone number</label>
                      <input type="text" className="form-control" id="phone" placeholder="Enter your number" name="phone-number" />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="email">Email</label>
                      <input type="email" className="form-control" id="email" placeholder="Email" name="email-address" />
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="message">Write Your Message</label>
                      <textarea className="form-control" placeholder="Write Your Message" id="message" rows="6" name="message"></textarea>
                    </div>
                    {/* <div data-netlify-recaptcha="true"></div> */}
                    <div className="col-md-12">
                      <button className="btn btn-solid" type="submit">Send Your Message</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {/* <div className="row">
                            <div className="col-sm-12">
                                <form name="contact" method="post" className="theme-form" data-netlify="true" data-netlify-honeypot="bot-field" data-netlify-recaptcha="true">
                                    <input type="hidden" name="form-name" value="contact" />
                                    <input type="hidden" name="bot-field" />
                                    <div className="form-row">
                                        <div className="col-md-6">
                                            <label htmlFor="name">First Name</label>
                                            <input type="text" className="form-control" id="name" placeholder="Enter Your First Name" name="first-name" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="lastname">Last Name</label>
                                            <input type="text" className="form-control" id="lastname" placeholder="Enter Your Last Name" name="last-name" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="phone">Phone number</label>
                                            <input type="text" className="form-control" id="phone" placeholder="Enter your number" name="phone-number" />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" className="form-control" id="email" placeholder="Email" name="email-address" />
                                        </div>
                                        <div className="col-md-12">
                                            <label htmlFor="message">Write Your Message</label>
                                            <textarea className="form-control" placeholder="Write Your Message" id="message" rows="6" name="message"></textarea>
                                        </div>
                                        <div className="col-md-12" data-netlify-recaptcha="true"></div>
                                        <div className="col-md-12">
                                            <button className="btn btn-solid" type="submit">Send Your Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> */}
          </div>
        </section>




        {/* <section className="about-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="banner-section">
                  <img
                    src={require("../assets/images/about/about-us.jpg")}
                    className="img-fluid blur-up lazyload"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <h4>How To Contact ThrowThings.com</h4>
                <p><strong><u>Contact Us Online</u></strong></p>
                <p>Use the online messaging available on the lower right corner of many pages.</p>
                <p><strong><u>Want to email us?</u></strong></p>
                <p>Send an email to info@ThrowThings.com</p>
                <p><strong><u>Need to Talk to Us in Person?</u></strong></p>
                <p>Feel free to call us at 330-297-1752 anytime. If we aren't in or available when you call please leave a message <strong><u>including your phone number</u></strong> and let us know how if there are certain times that would be best to call you back.</p>
              </div>
 */}

        {/* <div class="row section-b-space">
                <div class="col-lg-5">
                  <div class="contact-right">
                    <ul>
                      <li>
                        <div class="contact-icon">
                          <img src={phone} alt="Generic placeholder image"/>
                          <h6>Contact Us</h6>
                        </div>
                        <div class="media-body">
                          <p>+91 123 - 456 - 7890</p>
                          <p>+86 163 - 451 - 7894</p>
                        </div>
                      </li>
                      <li>
                        <div class="contact-icon">
                          <i class="fa fa-map-marker" aria-hidden="true"></i>
                          <h6>Address</h6>
                        </div>
                        <div class="media-body">
                          <p>ABC Complex,Near xyz, New York</p>
                          <p>USA 123456</p>
                        </div>
                      </li>
                      <li>
                        <div class="contact-icon">
                          <img src={email} alt="Generic placeholder image"/>
                          <h6>Address</h6>
                        </div>
                        <div class="media-body">
                          <p>Support@Shopcart.com</p>
                          <p>info@shopcart.com</p>
                        </div>
                      </li>
                      <li>
                        <div class="contact-icon">
                          <i class="fa fa-fax" aria-hidden="true"></i>
                          <h6>Fax</h6>
                        </div>
                        <div class="media-body">
                          <p>Support@Shopcart.com</p>
                          <p>info@shopcart.com</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}

        {/* <section className=" contact-page section-b-space">
                    <div className="container">
<div className="row section-b-space">
<div className="col-lg-5">
                                <div className="contact-right">
                                    <ul>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={phone} alt="Generic placeholder image" />
                                                    <h6>Contact Us</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>(330) 297-1752</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <i className="fa fa-map-marker" aria-hidden="true"></i>
                                                <h6>Address</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>ABC Complex,Near xyz, New York</p>
                                                <p>USA 123456</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                                <img src={email} alt="Generic placeholder image" />
                                                    <h6>Address</h6>
                                            </div>
                                            <div className="media-body">
                                                <p>Support@Shopcart.com</p>
                                                <p>info@shopcart.com</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-icon">
                                            <img src={phone} alt="Generic placeholder image" />
                                                    <h6>Contact Us</h6>
 */}
        {/* <i className="fa fa-fax" aria-hidden="true"></i>
                                                <h6>Fax</h6> */}
        {/* </div>
                                            <div className="media-body">
                                              <div>Call Us</div>
                                                <p>330.297.1752</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                      </div>
                      </div>
                      </section> */}

        {/* <div class="row">
                <div class="col-sm-12">
                  <form class="theme-form">
                    <div class="form-row">
                      <div class="col-md-6">
                        <label for="name">First Name</label>
                        <input type="text" class="form-control" id="name" placeholder="Enter Your name"/>
                      </div>
                      <div class="col-md-6">
                        <label for="email">Last Name</label>
                        <input type="text" class="form-control" id="last-name" placeholder="Email"/>
                      </div>
                      <div class="col-md-6">
                        <label for="review">Phone number</label>
                        <input type="text" class="form-control" id="review" placeholder="Enter your number"/>
                      </div>
                      <div class="col-md-6">
                        <label for="email">Email</label>
                        <input type="text" class="form-control" id="email" placeholder="Email"/>
                      </div>
                      <div class="col-md-12">
                        <label for="review">Write Your Message</label>
                        <textarea class="form-control" placeholder="Write Your Message" id="exampleFormControlTextarea1" rows="6"></textarea>
                      </div>
                      <div class="col-md-12">
                        <button class="btn btn-solid" type="submit">Send Your Message</button>
                      </div>
                    </div>
                  </form>
                </div>
               </div>
            </div>
          </div>
        </section> */}
      </Container>
    </Layout>
  )
}

export default Contact
